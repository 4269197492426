export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const GET_AUTH = "GET_AUTH";
export const PATCH_AUTH = "PATCH_AUTH";
export const GET_AUTH_PROJECTS = "GET_AUTH_PROJECTS";

export const ASSIGNDLG_OPEN = "ASSIGNDLG_OPEN";
export const ASSIGNDLG_CLOSE = "ASSIGNDLG_CLOSE";

export const FILTERDLG_OPEN = "FILTERDLG_OPEN";
export const FILTERDLG_CLOSE = "FILTERDLG_CLOSE";

export const ACCESSDLG_OPEN = "ACCESSDLG_OPEN";
export const ACCESSDLG_CLOSE = "ACCESSDLG_CLOSE";

export const SUCCESSALERT_OPEN = "SUCCESSALERT_OPEN";
export const SUCCESSALERT_CLOSE = "SUCCESSALERT_CLOSE";

export const ERRORALERT_OPEN = "ERRORALERT_OPEN";
export const ERRORALERT_CLOSE = "ERRORALERT_CLOSE";
