import React, { useState, useMemo } from 'react'
import { Modal, Button } from 'react-bootstrap'

import '../css/contact-us.scss'
import { animateScroll as scroll } from 'react-scroll'


const FourthPage = () => {
    const [modalShow, setModalShow] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [userCompany, setUserCompany] = useState('');

    useMemo(()=>{
        scroll.scrollToBottom();
    }, []);

    const formatPhoneNumber = (value) => {
        if (!value) return '';
        
        // Remove all non-digit characters
        const phoneNumber = value.replace(/[^\d]/g, '');
        const phoneNumberLength = phoneNumber.length;

        if (phoneNumberLength < 4) {
            return phoneNumber;
        }

        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }

        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    };

    const handleChange = (e) => {
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        setUserPhone(formattedPhoneNumber);
    };

    const contactAdmin = () => {
        if(!firstName || !lastName || !userEmail) {
            alert("Please fill the required fields")
            return
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({first_name: firstName, last_name: lastName, user_email: userEmail, user_company: userCompany, user_phone: userPhone })
        };
        fetch(process.env.REACT_APP_SERVER_URL + '/send_email_request/', requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log("Response : ", data);
                if (data.data && data.data.message === 'success') setModalShow(true);
            });
    }
    return (<div className="first-page contact-us">
        <div className="header-part header-contactus">
            <div className="header-title">
                <h2>Get In Touch</h2>
                <h3>
                    Drop us a line, and one of our dedicated representatives will be in touch with you right away.
                </h3>
            </div>
        </div>
        <div className="main-content" style={{ paddingBottom: 27 }}>
            <div className="config-content config-sell" style={{ borderBottom: '2px solid #26455d', paddingBottom: '1.5%' }}>
                <div className="config-sell-left">
                    <p className="title">
                        <b>How It Works</b>
                    </p>

                    <ul>
                        <li>
                            <div>1</div>
                            <p>
                                A member of our team will get in touch with you directly to discuss how we can create an amazing experience tailered specifically to your business's needs.
                            </p>
                        </li>
                        <li>
                            <div>2</div>
                            <p>
                                We will gather the photos, references, and information from you that we will need to accurately represent your products in stunning 3D.
                            </p>
                        </li>
                        <li>
                            <div>3</div>
                            <p>
                                After your configurator is built, which typically takes 3 to 7 days, one of our dedicated developers will provide assistance with implementing the experiece on your website.
                            </p>
                        </li>
                    </ul>
                </div>

                <div className="config-sell-right">
                    <div className="show-me-card">
                        <div className="header">
                            <h3>
                                Let's Get Started!
                            </h3>
                        </div>
                        <div className="content">
                            <div className='row'>
                                <div className='col-md-6 col-sm-12 item-field'>
                                    <p>First Name<span>*</span></p>
                                    <input type="text" placeholder=''value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                </div>
                                <div className='col-md-6 col-sm-12 item-field'>
                                    <p>Last Name<span>*</span></p>
                                    <input type="text" placeholder='' value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                </div>
                                <div className='col-md-12 col-sm-12 item-field'>
                                    <p>Email Address<span>*</span></p>
                                    <input type="text" placeholder='' value={userEmail} onChange={(e) => setUserEmail(e.target.value)} />
                                    <label>
                                        Please complete this required field.
                                    </label>
                                </div>
                                <div className='col-md-12 col-sm-12 item-field'>
                                    <p>Phone Number</p>
                                    {/* <input type="text" placeholder='' value={userPhone} onChange={(e) => setUserPhone(e.target.value)} /> */}
                                    <input
                                        type="text"
                                        placeholder="(123) 456-7890"
                                        value={userPhone}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='col-md-12 col-sm-12 item-field'>
                                    <p>Company Name</p>
                                    <input type="text" placeholder='' value={userCompany} onChange={(e) => setUserCompany(e.target.value)} />
                                </div>

                                <div className='col-md-12 col-sm-12 item-field display-center'>
                                    <button className='learn-more' onClick={contactAdmin}>
                                        Submit
                                    </button>
                                </div>

                            </div>

                         
                        </div>

                    </div>
                    <p className='phone-number'>
                        Or give us a call at <b>415-636-8802</b>
                    </p>
                </div>
            </div>
        </div>

        <Modal
            show={modalShow}
            onHide={() => setModalShow(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="contact-us"
        >
            <Modal.Header closeButton>
            </Modal.Header>

            <Modal.Body>
                <div className="email-success">
                    <img src='logo192.png' alt='Logo' />
                    <h3>
                        Your Request Has Been Sent!
                    </h3>
                    <p>
                        Thanks for contacting us here at Digital Shed Builders. <br />
                        We will be in touch with you shortly.
                    </p>
                </div>
            </Modal.Body>
            <Modal.Footer onClick={() => setModalShow(false)}>
                <Button >Done</Button>
            </Modal.Footer>

        </Modal>

    </div>)
}
export default FourthPage
