import React from 'react'
import { connect } from "react-redux";
import { ZSTDDecoder } from 'zstddec';
import { getAuthProjects } from '../../store/actions/auth';
import { Modal, Button } from 'react-bootstrap'
import { FaTimes, FaChevronDown } from 'react-icons/fa';
import Collapse from '@mui/material/Collapse';
// import Chart from 'chart.js/auto';
// import AnalyticsComp from "../comps/analytics"
import AnalyticsComp from "../comp/customer-anayltics"
import ReferalsComp from "../comp/referals-piechart"
import { authCheckState, updateAuthUser } from "../../store/actions/auth";


import "../css/project-comp.scss";
import axios from 'axios';

import Pricing from '../pricing/Pricing'

import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#ff9f69',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: 'gray',
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: 'gray',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

// Create our number formatter.
const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const timePeriods = [
    "Day", "Month", "Year"
]
const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
]

const statusOptions = {
    new_lead: { name: "New Lead", color: "#4C91A2" },
    reached_out: { name: "Reached Out", color: "#D9826F" },
    email_called: { name: "Email/Called", color: "#2E6E56" },
    response: { name: "Response", color: "#1A4B56" },
    closed_lost: { name: "Closed Lost", color: "#E97E4D" },
    closed_won: { name: "Closed Won", color: "#F29C42" },
    quote: { name: "Quote", color: "#6A2D5C" }
};

class Comp extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedProject: {},
            quotesShow: false,
            transactionsShow: false,
            modalShow: false,
            selectedQuote: {},
            collapseOpen: true,
            pViewCollapseOpen: true,
            pricingViewCollapseOpen: true,
            timePeriod: "Year",
            years: [],
            selectedYear: -1,
            selectedMonth: -1,
            selectedDay: -1,
            showOnlyMatching: false,
            sortColumn: 'date',
            sortDirection: 'asc',
            searchQuery: '',
            isDisabled: true,
            selectedItem: '',
            selectedUser: false,
            addNewUser: false,
            employees: [],
            employeeToEdit: null,
            employerUser: true,
            showPassword: false,
            employeeToDelete: false,
            employeeToDeleteId: '',
            signedUser: {},
            quotes: [],

            CustomerViewData: {
                labels: months,
                datasets: [
                    {
                        label: 'Views',
                        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        backgroundColor: '#26455d',
                    }
                ]
            },

            CustomerViewOption: {
                responsive: true,
                plugins: {
                    legend: {
                        display: false,
                    },
                    title: {
                        display: false,
                        text: 'Customer Views',
                    },
                },
                scales: {
                    y: {
                        suggestedMin: 0,
                        suggestedMax: 10,
                    },
                }
            },
            CustomerViewRedraw: false,
            RefererViewData: {
                labels: ["Other"],
                views: [0],
                datasets: [
                    {
                        label: '%',
                        data: [100],
                        backgroundColor: [
                            "#6e7c7c"
                        ],
                        borderWidth: 1,
                    },
                ],
            },

            savePricingModalShow: false,

            showAnalyticsSection: true,

            showPricing: true,

            transDetailmodalShow: false,

            subscription: null

        }
    }



    componentDidMount = async () => {

        const { getAuthProjectArr, authUser, auth_employee_user } = this.props
        // this.setState({
        //     quotes: [...authUser.customer_quotes],
        // });
        let that = this
        if (!auth_employee_user.role) {
            this.setState({ signedUser: authUser, employerUser: false });
        } else {
            this.setState({ signedUser: auth_employee_user });
        }
        axios.get(process.env.REACT_APP_SERVER_URL + '/api/employees/', {
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Token ${token}`,  // Add the JWT token here if needed
            }
        })
            .then(response => {
                // if(auth_employee_user.role === "Admin"){
                //     this.setState({ employees: response.data }); 
                // }else if(auth_employee_user.role === "Standard"){
                //     this.setState({ employees: [auth_employee_user] });
                // }else{
                //     this.setState({ employees: response.data });
                // }
                this.setState({ employees: response.data });
                if (!auth_employee_user.role) {
                    this.setState({ employerUser: false });
                }
            })
            .catch(err => console.error('Error fetching employees:', err));

        if (authUser.id) {
            getAuthProjectArr(authUser.id, (flag) => {
                const { authProjects } = that.props
                const showAnalyticsSectionValues = JSON.parse(localStorage.getItem('showAnalyticsSection'));
                let showAnalyticsSection = true;
                if (showAnalyticsSectionValues) {
                    showAnalyticsSection = showAnalyticsSectionValues?.find(value => value.project_id === authProjects[0].id) ? showAnalyticsSectionValues?.find(value => value.project_id === authProjects[0].id)?.value : true
                }
                if (flag && authProjects.length > 0) that.setState({ selectedProject: authProjects[0], showAnalyticsSection })
            });
        }

        if (authUser.subscribe_id && authUser.subscribe_id !== '') {
            this.getSubscription(authUser.subscribe_id)
        }

        const today = new Date();
        const selectedMonth = today.getMonth(); //months from 1-12
        const selectedDay = today.getDate();
        const selectedYear = today.getFullYear();
        const years = []

        for (let i = 0; i < 12; i++) {
            years.push(selectedYear - i)
        }

        this.setState({ years, selectedYear, selectedDay, selectedMonth })
    }

    componentDidUpdate(prevProps, prevState) {
        const { timePeriod, selectedDay, selectedMonth, selectedYear, selectedProject
            , CustomerViewData, CustomerViewRedraw, RefererViewData, CustomerViewOption } = this.state

        if ((timePeriod == prevState.timePeriod)
            && (selectedDay == prevState.selectedDay)
            && (selectedMonth == prevState.selectedMonth)
            && (selectedYear == prevState.selectedYear)
            && (selectedProject.id == prevState.selectedProject?.id))
            return

        if (!selectedProject.id || CustomerViewRedraw) return

        let startStamp, endStamp

        if (timePeriod == "Year") {
            startStamp = new Date(selectedYear, 0, 1)
            startStamp = Math.floor(startStamp.getTime() / 1000)
            endStamp = new Date(selectedYear + 1, 0, 1, 0, 0, 0, 0)
            endStamp = Math.floor(endStamp.getTime() / 1000)
        } else if (timePeriod == 'Month') {
            startStamp = new Date(selectedYear, selectedMonth)
            startStamp = Math.floor(startStamp.getTime() / 1000)
            const dayNum = new Date(selectedYear, selectedMonth, 0).getDate();
            endStamp = startStamp + dayNum * 24 * 3600
        } else if (timePeriod == 'Day') {
            startStamp = new Date(selectedYear, selectedMonth, selectedDay)
            startStamp = Math.floor(startStamp.getTime() / 1000)
            endStamp = startStamp + 24 * 3600
        }

        console.log("time period changed: ", timePeriod, startStamp, endStamp)

        axios
            .post(process.env.REACT_APP_SERVER_URL + "/get_project_traffic/", {
                proj_id: selectedProject.id,
                startStamp,
                endStamp
            })
            .then(res => {
                const data = res.data;
                let labels = []
                let viewData = []
                console.log("data: ", data);
                if (timePeriod == "Year") {

                    months.map((month, i) => {
                        const startT = new Date(selectedYear, i, 1).getTime() / 1000
                        const endT = (i < 11 ? new Date(selectedYear, i + 1).getTime() : new Date(selectedYear + 1, 1).getTime()) / 1000
                        const filteredData = data.data.filter(item => item.timestamp_data > startT && item.timestamp_data < endT)
                        viewData.push(filteredData.length)
                        labels.push(month)
                        return month
                    })

                } else if (timePeriod == 'Month') {

                    const dayNum = new Date(selectedYear, selectedMonth * 1 + 1, 0).getDate();

                    for (let i = 0; i < dayNum; i++) {
                        const startT = new Date(selectedYear, selectedMonth, i + 1).getTime() / 1000
                        const endT = startT + 24 * 3600
                        const filteredData = data.data.filter(item => item.timestamp_data > startT && item.timestamp_data < endT)
                        viewData.push(filteredData.length)
                        labels.push(i + 1)
                    }
                    endStamp = startStamp + dayNum * 24 * 3600

                } else if (timePeriod == 'Day') {

                    for (let i = 0; i < 12; i++) {
                        const startT = new Date(selectedYear, selectedMonth, selectedDay).getTime() / 1000 + 2 * 3600 * i
                        const endT = startT + 2 * 3600
                        const filteredData = data.data.filter(item => item.timestamp_data > startT && item.timestamp_data < endT)
                        viewData.push(filteredData.length)
                        labels.push(`${i * 2}: 00 - ${(i + 1) * 2}:00`)
                    }

                }

                CustomerViewData.labels = labels
                CustomerViewData.datasets[0].data = viewData
                let maxCount = Math.max(...viewData)

                if (maxCount > 10)
                    maxCount = Math.round(maxCount * 1.2)
                else
                    maxCount = 10

                CustomerViewOption.scales.y.suggestedMax = maxCount
                const totalViews = data.data.length
                if (totalViews > 0) {

                    let leftViews = totalViews
                    const refererLabels = []
                    const refererColors = []
                    const refererData = []
                    const refererViews = []

                    data.refers.map(referer => {
                        refererLabels.push(referer.name)
                        refererColors.push(referer.label_color)
                        const filteredData = data.data.filter(item => item.referer.includes(referer.match_url))
                        refererData.push(Math.floor(filteredData.length * 100 / totalViews))
                        leftViews -= filteredData.length
                        refererViews.push(filteredData.length)
                        return referer
                    })

                    refererLabels.push("Other")
                    refererColors.push("#6e7c7c")
                    refererData.push(Math.floor(leftViews * 100 / totalViews))
                    refererViews.push(leftViews)

                    RefererViewData.labels = refererLabels
                    RefererViewData.views = refererViews
                    RefererViewData.datasets[0].data = refererData
                    RefererViewData.datasets[0].backgroundColor = refererColors


                }


                this.setState({ CustomerViewData, RefererViewData, CustomerViewRedraw: true }, () => {
                    this.setState({ CustomerViewRedraw: false })
                })

            })
            .catch(err => {
                console.log("auth failed : ", err.response, err.message)
            });

        this.getData()
    }

    getSubscription = (subscriptionId) => {
        const reqUrl = `${process.env.REACT_APP_SERVER_URL}/get_subscription/`
        axios
            .post(reqUrl, {
                subscriptionId,
            })
            .then(res => {
                const data = res.data;
                console.log('getSubscription: ', data);
                if (data.subscription) {
                    this.setState({ subscription: data.subscription })
                }
            })
            .catch(err => {
                console.log("auth failed : ", err.response, err.message)
            });
    }

    getData = async () => {
        const { selectedProject } = this.state
        const projUrl = selectedProject.id;
        const reqUrl = `${process.env.REACT_APP_SERVER_URL}/request_project_by_id/${projUrl}`
        // axios.get(reqUrl).then(response => {
        //     if (response.status === 200) {
        //         let project_data = response.data.data.result
        //         project_data.materials = []
        //         // let json_data = quote_id ? project.quote_json : project.json_data
        //         // let json_data = project_data.json_data
        //         let json_data;
        //         if (project_data.pricing_mandatory) {
        //             json_data = project_data?.pricing_data; // Use pricing_data if pricing_mandatory is true
        //             console.log("object", json_data);
        //         } else {
        //             json_data = project_data?.json_data; // Use json_data if pricing_mandatory is false
        //         }
        //         console.log('project_data: ', project_data);
        //         console.log('json_data: ', json_data);
        //         const showPricing = project_data?.showPricing === undefined ? true : project_data?.showPricing;
        //         this.setState({
        //             project_data,
        //             showPricing,
        //             json_data,
        //         })
        //     }
        // })
        // try {
        //     const response = await axios.get(reqUrl, {
        //         headers: {
        //             'Content-Type': 'application/json'
        //         }
        //     });

        //     if (response.status === 200) {
        //         const base64CompressedData = response.data.compressed_data;

        //         // Decode Base64 to Uint8Array
        //         const binaryString = atob(base64CompressedData);
        //         const compressedData = new Uint8Array(binaryString.length);
        //         for (let i = 0; i < binaryString.length; i++) {
        //             compressedData[i] = binaryString.charCodeAt(i);
        //         }
        //         console.log("Size of compressed data:", compressedData.byteLength, "bytes");

        //         // Initialize ZSTDDecoder and set up the decompression
        //         const zstd = new ZSTDDecoder();
        //         await zstd.init(); // Initialize the decoder (it fetches the WebAssembly module)

        //         // Decompress the data
        //         const decompressedData = zstd.decode(compressedData);

        //         // Convert the decompressed data to a string and then parse it as JSON
        //         const jsonString = new TextDecoder().decode(decompressedData);
        //         const project_data = JSON.parse(jsonString);

        //         // Process the project data as you normally would
        //         project_data.materials = [];

        //         let json_data;
        //         if (project_data.pricing_mandatory) {
        //             json_data = project_data?.pricing_data; // Use pricing_data if pricing_mandatory is true
        //             console.log("object", json_data);
        //         } else {
        //             json_data = project_data?.json_data; // Use json_data if pricing_mandatory is false
        //         }
        //         console.log('project_data: ', project_data);
        //         console.log('json_data: ', json_data);
        //         const showPricing = project_data?.showPricing === undefined ? true : project_data?.showPricing;
        //         this.setState({
        //             project_data,
        //             showPricing,
        //             json_data,
        //         });
        //     }
        // } catch (error) {
        //     console.error("Error fetching and decompressing project data:", error);
        // }
        axios.get(reqUrl).then(response => {
            if (response.status === 200) {
                console.log((">>>>>>>>>>>>>>>>>>"));
                const base64CompressedData = response.data.compressed_data;
                const binaryString = atob(base64CompressedData);
                const compressedData = new Uint8Array(binaryString.length);
                for (let i = 0; i < binaryString.length; i++) {
                    compressedData[i] = binaryString.charCodeAt(i);
                }
                const zstd = new ZSTDDecoder();
                zstd.init().then(() => {
                    const decompressedData = zstd.decode(compressedData);
                    const jsonString = new TextDecoder().decode(decompressedData);
                    const project_data = JSON.parse(jsonString);
                    console.log("project_data", project_data);
                    project_data.materials = []
                    let json_data = project_data.json_data
                    console.log('project_data: ', project_data);
                    console.log('json_data: ', json_data);
                    const showPricing = project_data?.showPricing === undefined ? true : project_data?.showPricing;
                    this.setState({
                        project_data,
                        showPricing,
                        json_data,
                    })
                })
            }
        })
    }

    togglePasswordVisibility = () => {
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword,
        }));
    };

    handleAddUser = (e) => {
        e.preventDefault();
        const { authUser, auth_check } = this.props
        const { employeeToEdit, employeeToDelete, employeeToDeleteId } = this.state;
        const token = localStorage.getItem('token');
        const newUser = {
            name: e.target.username.value,
            email: e.target.email.value,
            password: e.target.password.value,
            role: e.target.role.value,
            useremail: authUser.useremail,
        };

        if (employeeToEdit && !employeeToDelete) {
            // Update existing employee
            if (!token) {
                console.error('No token found, please log in.');
                // Optionally, redirect to login page or show an error message
            } else {
                axios.put(`${process.env.REACT_APP_SERVER_URL}/update_employee/${employeeToEdit.id}`, newUser)
                    .then(res => {
                        if (res.status !== 200) {
                            throw new Error('Network response was not ok');
                        }
                        return res.data; // Use res.data instead of res.json()
                    })
                    .then(updatedEmployee => {
                        auth_check()
                        const updatedEmployees = this.state.employees.map(emp =>
                            emp.id === updatedEmployee.id ? updatedEmployee : emp
                        );
                        this.setState({
                            employees: updatedEmployees,
                            employeeToEdit: null,
                            addNewUser: false,
                        });
                    })
                    .catch(err => console.error('Error updating employee:', err));
            }
        } else if (employeeToEdit && employeeToDelete) {
            axios.delete(`${process.env.REACT_APP_SERVER_URL}/update_employee/${employeeToDeleteId}`, {
                headers: {
                    // 'Authorization': `Token ${token}`,  // Include the token for authentication
                    // 'Content-Type': 'application/json',
                },
            })
                .then(() => {
                    // Remove the deleted employee from state
                    auth_check()
                    this.setState({ employees: this.state.employees.filter(emp => emp.id !== employeeToDeleteId), employeeToEdit: null, employeeToDelete: false, addNewUser: false });
                })
                .catch(err => console.error('Error deleting employee:', err));
        } else {
            // Add new employee
            axios.post(process.env.REACT_APP_SERVER_URL + '/api/employees/', newUser)
                .then(response => {
                    auth_check()
                    this.setState({
                        employees: [...this.state.employees, response.data],  // Use response.data to access the new employee data
                        addNewUser: false,
                    });
                })
                .catch(err => console.error('Error adding user:', err));
        }
    }

    handleEditEmployee = (employee) => {
        this.setState({ employeeToEdit: employee, addNewUser: true });
    }

    // handleDeleteEmployee = (id) => {
    //     // const token = localStorage.getItem('token');  // Ensure the token is retrieved
    //     // if (!token) {
    //     //     console.error('No authentication token found');
    //     //     return;
    //     // }

    //     axios.delete(`${process.env.REACT_APP_SERVER_URL}/update_employee/${id}`, {  // Adjust URL to match your Django endpoint
    //         headers: {
    //             // 'Authorization': `Token ${token}`,  // Include the token for authentication
    //             // 'Content-Type': 'application/json',
    //         },
    //     })
    //     .then(() => {
    //         // Remove the deleted employee from state
    //         this.setState({ employees: this.state.employees.filter(emp => emp.id !== id) });
    //     })
    //     .catch(err => console.error('Error deleting employee:', err));
    // };

    handleDeleteEmployee = (employee, id) => {
        this.setState({ employeeToEdit: employee, addNewUser: true, employeeToDelete: true, employeeToDeleteId: id });
    };

    handleStatusChange = (e, quote) => {
        const { authUser, update_auth } = this.props;
        // debugger
        const newStatus = e.target.value;
        const quoteRefDate = quote.ref_date;
        // const quoteId = quote.id;

        // call redux to update the auth user info:
        let user = { ...authUser }
        user.customer_quotes.find(q => q.id == quote.id).status = newStatus
        update_auth(user)

        if (quoteRefDate) {
            axios.put(`${process.env.REACT_APP_SERVER_URL}/update-quote-status/`, {
                ref_date: quoteRefDate,
                new_status: newStatus
            })
                .then(res => {
                    if (res.status === 200) {
                        console.log('Status updated successfully:', res.data);
                        // auth_check();
                        // Extract the updated status from the response
                        // const updatedStatus = res.data.updated_status;
                        // this.setState(prevState => ({
                        //     quotes: prevState.quotes.map(q =>
                        //         q.id === quoteId ? { ...q, status: updatedStatus } : q
                        //     )
                        // }), () => {
                        //     console.log("Quotes after update:", this.state.quotes);
                        // });
                    } else {
                        throw new Error('Network response was not ok');
                    }
                })
                .catch(err => console.error('Error updating status:', err));
        }
    };

    selectProject = (proj) => {
        const showAnalyticsSectionValues = JSON.parse(localStorage.getItem('showAnalyticsSection'));
        let showAnalyticsSection = true;
        if (showAnalyticsSectionValues) {
            showAnalyticsSection = showAnalyticsSectionValues?.find(value => value.project_id === proj.id) ? showAnalyticsSectionValues?.find(value => value.project_id === proj.id)?.value : true;
        }

        this.setState({
            selectedProject: proj,
            showAnalyticsSection,
            selectedUser: false,
            transactionsShow: false,
            selectedItem: ''
        });
    }

    select_year = (e) => {
        this.setState({ selectedYear: e.target.value })
    }

    select_month = (e) => {
        this.setState({ selectedMonth: e.target.value })
    }


    select_day = (e) => {
        this.setState({ selectedDay: e.target.value })
    }

    toggleQuotesShow = () => {
        let { quotesShow } = this.state
        quotesShow = !quotesShow
        this.setState({ selectedUser: false, quotesShow, transactionsShow: false, selectedItem: '' })
    }

    toggleTransactionsShow = () => {
        this.setState({ selectedUser: false, transactionsShow: true, quotesShow: false })
    }

    toggle_new_user = (item) => {
        if (item == 'Cancel New User') {
            this.setState({ addNewUser: false })
        } else {
            this.setState({ addNewUser: true })
        }
    }

    getDateTime = (str) => {
        const date = new Date(str.substring(0, 10))
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    }

    // getPhoneNumber = (str) => {
    //     return `(${str.substring(0, 3)}) ${str.substring(3, 6)}-${str.substring(6, 10)}`
    // }

    getPhoneNumber = (str) => {
        if (!str) return '';
        // Remove any non-digit characters
        const phoneNumber = str.replace(/[^\d]/g, '');
        const phoneNumberLength = phoneNumber.length;

        if (phoneNumberLength < 4) {
            return phoneNumber;
        }

        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }

        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    }

    setModalShow = (flag) => {
        this.setState({ modalShow: flag })
    }

    setSavePricingModalShow = (flag) => {
        this.setState({ savePricingModalShow: flag })
    }

    setTransDetailModalShow = (flag) => {
        this.setState({ transDetailmodalShow: flag })
    }

    selectQuote = (selectedQuote) => {
        console.log('selectedQuote: ', selectedQuote);
        this.setState({ selectedQuote, modalShow: true })
    }

    selectTransaction = (selectedTransaction) => {
        console.log('selectedTransaction: ', selectedTransaction);
        const transId = selectedTransaction.transId;
        const reqUrl = `${process.env.REACT_APP_SERVER_URL}/get_transaction_detail/`
        axios
            .post(reqUrl, {
                transId
            })
            .then(res => {
                const data = res.data;
                console.log('HERE: ', data);
                if (data.transaction) {
                    this.setState({ selectedTransaction: data.transaction, transDetailmodalShow: true })
                }
            })
            .catch(err => {
                console.log("auth failed : ", err.response, err.message)
            });
    }

    toggleShowOnlyMatching = () => {
        this.setState(prevState => ({
            showOnlyMatching: !prevState.showOnlyMatching
        }));
    }

    // toggleSearchBox = () => {
    //     this.setState(prevState => ({
    //         isSearchVisible: !prevState.isSearchVisible
    //     }));
    // }

    handleSearchIconClick = () => {
        this.setState((prevState) => ({
            isDisabled: !prevState.isDisabled  // Toggle disabled state
        }));
    };

    handleSearchInputChange = (e) => {
        let { authUser } = this.props
        this.setState({ searchQuery: e.target.value });
    };


    handleSort = (column) => {
        const { sortColumn, sortDirection } = this.state;
        const newDirection = (sortColumn === column && sortDirection === 'asc') ? 'desc' : 'asc';
        this.setState({
            sortColumn: column,
            sortDirection: newDirection,
        });
    }

    handleEmployeeSelect = (event, quote) => {
        const { auth_allemployee_user, authUser, update_auth } = this.props;
        const selectedEmployeeId = event.target.value;
        // console.log("auth_allemployee_user: ", auth_allemployee_user)
        let selectedEmployee = auth_allemployee_user.find(
            (employee) => employee.id === selectedEmployeeId
        );
        console.log("selectedEmployee : ", selectedEmployeeId)
        if (!selectedEmployee) selectedEmployee = {}

        // call redux to update the auth user info:
        let user = { ...authUser }
        user.customer_quotes.find(q => q.id == quote.id).assigned_user = event.target.value
        update_auth(user);
        // if(!event.target.value) return
        const employee_quote = {
            employee_id: selectedEmployeeId,
            assigned_quote: quote.id
        };



        axios.post(`${process.env.REACT_APP_SERVER_URL}/update_quote_employee/`, employee_quote)
            .then(res => {
                if (res.status !== 200) {
                    throw new Error('Network response was not ok');
                }
                return res.data; // Use res.data instead of res.json()
            })
            .then(() => {
                // auth_check()
                // console.log("updatedEmployee: ", updatedEmployee)
                // const updatedEmployees = employees.map(emp =>
                //     emp.id === updatedEmployee.id ? updatedEmployee : emp
                // );
                // this.setState({
                //     employees: updatedEmployees,
                //     // employeeToEdit: null,
                //     // addNewUser: false,
                // });
            })
            .catch(err => console.error('Error updating employee:', err));

    };

    getSortedQuotes = () => {
        const { selectedProject, authUser } = this.props;
        const { sortColumn, sortDirection, showOnlyMatching, searchQuery } = this.state;

        const quotes = authUser.customer_quotes
        // Filter based on showOnlyMatching and searchQuery

        let filteredQuotes = [...quotes];

        if (!showOnlyMatching && selectedProject && selectedProject.project_name) {
            filteredQuotes = filteredQuotes.filter(quote => selectedProject.project_name === quote.shed_name);
        }

        if (searchQuery) {
            filteredQuotes = filteredQuotes.filter(quote => {
                const lowerCaseQuery = searchQuery.toLowerCase();

                // Format the date to a readable string for comparison (e.g., 'YYYY-MM-DD')
                const quoteDate = new Date(quote.date_time).toLocaleDateString('en-CA'); // Example format: 'YYYY-MM-DD'

                // Check if any of the fields match the search query
                return (
                    (quote.initial_purchase_item && quote.initial_purchase_item.toLowerCase().includes(lowerCaseQuery)) ||
                    (`${quote.first_name} ${quote.last_name}`.toLowerCase().includes(lowerCaseQuery)) ||
                    (quote.user_email && quote.user_email.toLowerCase().includes(lowerCaseQuery)) ||
                    (quote.shed_name && quote.shed_name.toLowerCase().includes(lowerCaseQuery)) ||
                    (quote.user_zipcode && quote.user_zipcode.toLowerCase().includes(lowerCaseQuery)) ||
                    (quote.assigned_user && quote.assigned_user.toLowerCase().includes(lowerCaseQuery)) ||
                    (quote.status && statusOptions[quote.status]?.name.toLowerCase().includes(lowerCaseQuery)) ||
                    (quote.user_phone && quote.user_phone.toLowerCase().includes(lowerCaseQuery)) ||
                    (quoteDate.includes(lowerCaseQuery))  // Check if date matches the query
                );
            });
        }

        return filteredQuotes.sort((a, b) => {
            if (sortColumn === 'date') {
                return sortDirection === 'asc' ? new Date(a.date_time) - new Date(b.date_time) : new Date(b.date_time) - new Date(a.date_time);
            } else if (sortColumn === 'name') {
                const nameA = `${a.first_name} ${a.last_name}`.toLowerCase();
                const nameB = `${b.first_name} ${b.last_name}`.toLowerCase();
                return sortDirection === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
            } else if (sortColumn === 'email') {
                return sortDirection === 'asc' ? a.user_email.localeCompare(b.user_email) : b.user_email.localeCompare(a.user_email);
            } else if (sortColumn === 'zip') {
                return sortDirection === 'asc' ? a.user_zipcode.localeCompare(b.user_zipcode) : b.user_zipcode.localeCompare(a.user_zipcode);
            } else if (sortColumn === 'style') {
                return sortDirection === 'asc' ? a.shed_name.localeCompare(b.shed_name) : b.shed_name.localeCompare(a.shed_name);
            } else if (sortColumn === 'assign') {
                return sortDirection === 'asc' ? a.assigned_user.localeCompare(b.assigned_user) : b.assigned_user.localeCompare(a.assigned_user);
            } else if (sortColumn === 'status') {
                return sortDirection === 'asc' ? a.status.localeCompare(b.status) : b.status.localeCompare(a.status);
            } else if (sortColumn === 'phone') {
                const phoneA = a.user_phone.replace(/[^0-9]/g, ''); // Strip non-numeric characters
                const phoneB = b.user_phone.replace(/[^0-9]/g, ''); // Strip non-numeric characters
                return sortDirection === 'asc' ? phoneA.localeCompare(phoneB, undefined, { numeric: true }) : phoneB.localeCompare(phoneA, undefined, { numeric: true });
            }
            return 0;
        });
    };

    cancelSubscription = () => {
        const { authUser } = this.props
        window.location.href = `/payment?customer=${authUser.id}&amount=${authUser.subscribe_amount.value}&step=3`
    }

    selectItem(item) {
        this.setState({ selectedItem: item });
        if (item === 'User') {
            this.setState({ selectedUser: true });
        } else {
            this.setState({ selectedUser: false });
        }
    }

    toggle_collapse = (collapseOpen) => {
        this.setState({ collapseOpen })
    }

    toggle_pview_collapse = (pViewCollapseOpen) => {
        this.setState({ pViewCollapseOpen })
    }

    toggle_pricingview_collapse = (pricingViewCollapseOpen) => {
        this.setState({ pricingViewCollapseOpen })
    }

    select_time_period = (timePeriod) => {
        this.setState({ timePeriod })
    }

    get_days_map = (num) => {
        const days = []
        for (let i = 0; i < num; i++)days.push(i + 1)
        return days
    }

    getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    handleSavePricing = (json_data) => {
        const { selectedProject } = this.state;
        const projUrl = selectedProject.id;
        const reqUrl = `${process.env.REACT_APP_SERVER_URL}/request_project_by_id/${projUrl}`;
        const csrftoken = this.getCookie('csrftoken');
        const headers = { 'X-CSRFToken': csrftoken };
        let formData = new FormData();
        formData.append('json_data', JSON.stringify(json_data))

        axios.post(reqUrl, formData, { headers }).then(response => {
            if (response.status === 200) {
                // alert("Save Project Success");
                this.setSavePricingModalShow(true);
            } else {
            }
        })
    }

    orderFunc = (a, b) => {
        const Adate = new Date(a.date_time?.substring(0, 10))
        const Bdate = new Date(b.date_time?.substring(0, 10))
        if (Adate < Bdate)
            return 1
        else if (Adate > Bdate)
            return -1
        return 0
    }

    handleShowSection = (e, type) => {
        const { selectedProject } = this.state;
        this.setState({ selectedUser: false, transactionsShow: false, selectedItem: '' });

        if (type === 'Analytics') {
            let values = JSON.parse(localStorage.getItem('showAnalyticsSection'));
            const value = {
                project_id: selectedProject.id,
                value: e.target.checked
            }
            if (values) {
                values.push(value);
            } else {
                values = [value];
            }

            localStorage.setItem('showAnalyticsSection', JSON.stringify(values));
            this.setState({
                showAnalyticsSection: e.target.checked
            });
        } else if (type === 'Pricing') {
            this.setState({
                showPricing: e.target.checked
            });
            const projUrl = selectedProject.id;
            const reqUrl = `${process.env.REACT_APP_SERVER_URL}/request_project_by_id/${projUrl}`;
            const csrftoken = this.getCookie('csrftoken');
            const headers = { 'X-CSRFToken': csrftoken };
            let formData = new FormData();
            formData.append('showPricing', JSON.stringify(e.target.checked))

            axios.post(reqUrl, formData, { headers }).then(response => {
                if (response.status === 200) {
                    // alert("Save Project Success");
                    console.log('resposne: ', response);
                    let project_data = response.data.data.result
                    let json_data = project_data.json_data
                    console.log('project_data: ', project_data);
                    console.log('json_data: ', json_data);
                    this.setState({
                        project_data,
                        json_data,
                    })
                } else {
                }
            })
        }
    }

    printPDF = async () => {
        const { selectedTransaction } = this.state;
        console.log("print PDF: ")
        // const pdf = new jsPDF("portrait", "pt", "a4");
        const data = await document.querySelector(".transaction-detail-body");
        // pdf.html(data).then(() => {
        //   pdf.save(`${selectedTransaction?.transId}.pdf`);
        // });
        html2canvas(data)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'px', 'a4');
                const width = pdf.internal.pageSize.getWidth();
                const height = pdf.internal.pageSize.getHeight();

                pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
                pdf.save(`${selectedTransaction?.transId}.pdf`);
            });
    }

    render() {
        const { authProjects, authUser } = this.props

        const { signedUser, showPassword, employerUser, employees, employeeToEdit, employeeToDelete, isDisabled, selectedProject, showOnlyMatching, addNewUser, sortColumn, sortDirection, selectedItem, quotesShow, transactionsShow, selectedUser, modalShow, selectedQuote, selectedTransaction,
            collapseOpen, pViewCollapseOpen, pricingViewCollapseOpen, timePeriod, years, selectedYear, selectedMonth
            , selectedDay, CustomerViewData, CustomerViewRedraw, RefererViewData, CustomerViewOption, savePricingModalShow, transDetailmodalShow, subscription } = this.state

        const dayNum = new Date(selectedYear, selectedMonth * 1 + 1, 0).getDate();
        const days = dayNum > 0 ? this.get_days_map(dayNum) : [];
        const sortedQuotes = this.getSortedQuotes();
        const { project_data, json_data, showPricing } = this.state;
        const { showAnalyticsSection } = this.state;
        const arbTransactions = subscription?.arbTransactions || [];

        return <div className="project-comp">
            <div className="header-part header-example">
                <div className="header-title">
                    <h2>Project Dashboard</h2>
                </div>
            </div>
            <div className='price-blocks'>
                <div className='price-content'>
                    <div className='project-list'>
                        <h4>My {quotesShow ? "Quotes" : transactionsShow ? "Receipts" : "Projects"}</h4>
                        <ul>
                            {
                                authProjects.map(proj => <li key={proj.id}
                                    onClick={this.selectProject.bind(this, proj)}
                                    className={selectedProject.id === proj.id ? 'selected' : ''}>{proj.slug ? proj.slug : proj.project_name}</li>)
                            }
                        </ul>
                        <button className='view-quotes' onClick={this.toggleQuotesShow.bind(this)}>
                            {quotesShow && <img src={require("../../assets/member-page/white-arrow.png")} alt='return main' />}
                            {quotesShow ? "MAIN DASHBOARD" : "VIEW QUOTE REQUESTS"}
                        </button>

                        {/* <button className='view-quotes' style={{ marginTop: '8px' }} onClick={this.toggleTransactionsShow.bind(this)}>
                            {transactionsShow && <img src={require("../../assets/member-page/white-arrow.png")} alt='return main' />}
                            {transactionsShow ? "MAIN DASHBOARD" : "VIEW RECEIPTS"}
                        </button> */}
                        {!employerUser && (
                            <>
                                <div className='switch-box' style={{ marginTop: '16px' }}>
                                    <h4>Analytics</h4>
                                    <IOSSwitch sx={{ m: 1 }} checked={showAnalyticsSection}
                                        onChange={e => this.handleShowSection(e, 'Analytics')} />
                                </div>
                                <div className='switch-box pricing-part'>
                                    <h4>Pricing</h4>
                                    <IOSSwitch sx={{ m: 1 }} checked={showPricing}
                                        onChange={e => this.handleShowSection(e, 'Pricing')} />
                                </div>
                                <div className='switch-box account-settings'>
                                    <h4>Account Settings</h4>
                                </div>
                                <ul style={{ listStyleType: 'none', padding: '8px' }}>
                                    <li className={selectedItem === 'User' ? 'account-setting-selected' : ''} onClick={() => this.selectItem('User')}>
                                        User
                                    </li>
                                    {authUser.id && (
                                        <li
                                            className={selectedItem === 'Manage Subscription' ? 'account-setting-selected' : ''}
                                            onClick={() => {
                                                this.cancelSubscription();
                                                this.selectItem('Manage Subscription');
                                            }}
                                        >
                                            Manage Subscription
                                        </li>
                                    )}
                                    <li
                                        className={`view-receipts-quotes ${selectedItem === 'View Receipts' ? 'account-setting-selected' : ''
                                            }`}
                                        onClick={() => {
                                            this.toggleTransactionsShow();
                                            this.selectItem('View Receipts');
                                        }}
                                    >
                                        View Receipts
                                        {/* {transactionsShow ? 'Main Dashboard' : 'View Receipts'} */}
                                    </li>
                                </ul>
                            </>
                        )}
                    </div>

                    {
                        !selectedUser && transactionsShow && <div className='project-show'>
                            <h4>
                                Receipts
                                <button className={'btn-collapse' + (collapseOpen ? " opened" : "")} onClick={() => this.toggle_collapse(!collapseOpen)}>
                                    <FaChevronDown />
                                </button>
                            </h4>

                            <Collapse in={collapseOpen}>
                                <div className={`content ${transactionsShow ? "quote-show" : ""}`}>
                                    <div className='quote-list'>
                                        <table>
                                            <tbody>
                                                {
                                                    arbTransactions.sort(this.orderFunc)?.map(transaction =>
                                                        <tr key={transaction.transId}>
                                                            <td><button onClick={this.selectTransaction.bind(this, transaction)}>View Details</button></td>
                                                            <td>{transaction.transId}</td>
                                                            <td>{transaction.submitTimeUTC}</td>
                                                            <td>{`${subscription?.profile?.paymentProfile?.billTo?.firstName} ${subscription?.profile?.paymentProfile?.billTo?.lastName}`}</td>
                                                            <td>{formatter.format(subscription.amount)}</td>
                                                            {/* <td>{this.getPhoneNumber(quote.user_phone)}</td>
                                                            <td>{quote.user_zipcode}</td> */}
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                    }

                    {selectedUser && selectedItem === 'User' && (
                        <div className="project-show">
                            <h4>
                                Users
                                <button
                                    className={'btn-collapse' + (collapseOpen ? ' opened' : '')}
                                    onClick={() => this.toggle_collapse(!collapseOpen)}
                                >
                                    <FaChevronDown />
                                </button>
                            </h4>
                            <Collapse in={collapseOpen}>
                                {addNewUser ? (
                                    <div className="add-new-user" style={{ padding: '50px' }}>
                                        <form onSubmit={this.handleAddUser} style={{ display: 'inline-grid' }}>
                                            {/* Form inputs for username, email, password, role */}
                                            <label style={{ marginBottom: '5px', fontSize: 'larger' }}>User Name:</label>
                                            <input
                                                type="text"
                                                name="username"
                                                defaultValue={employeeToEdit ? employeeToEdit.name : ''}
                                                placeholder="User Name"
                                                required
                                                style={{ marginBottom: '25px', width: '40vw' }}
                                                autoComplete="off"
                                            />

                                            <label style={{ marginBottom: '5px', fontSize: 'larger' }}>Email Address:</label>
                                            <input
                                                type="email"
                                                name="email"
                                                defaultValue={employeeToEdit ? employeeToEdit.email : ''}
                                                placeholder="Email"
                                                required
                                                style={{ marginBottom: '25px', width: '40vw' }}
                                                autoComplete="off"
                                            />

                                            <label style={{ marginBottom: '5px', fontSize: 'larger' }}>Password:</label>
                                            <div style={{ position: 'relative', width: '40vw' }}>
                                                <input
                                                    type={showPassword ? 'text' : 'password'}
                                                    name="password"
                                                    // defaultValue={employeeToEdit ? employeeToEdit.password : ''}
                                                    defaultValue={employeeToEdit && employeeToEdit.password.length > 10
                                                        ? `${employeeToEdit.password.substring(0, 10)}...`
                                                        : employeeToEdit ? employeeToEdit.password : ''}
                                                    placeholder="Password"
                                                    required={!employeeToEdit} // Required only for new employee
                                                    style={{ marginBottom: '25px', width: '40vw', letterSpacing: '0.2em', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                                                    autoComplete="new-password"
                                                // maxLength={100}
                                                />
                                                <span
                                                    onClick={this.togglePasswordVisibility}
                                                    style={{
                                                        position: 'absolute',
                                                        right: '10px',
                                                        top: '2px',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    {showPassword ? (
                                                        <img style={{ width: '15px' }} src='show_eye.svg' alt='show' />
                                                    ) : (
                                                        <img style={{ width: '15px' }} src='hide_eye.svg' alt='hide' />
                                                    )}
                                                </span>
                                            </div>

                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <label style={{ marginRight: '34px', fontSize: 'larger' }}>User Role:</label>
                                                <div style={{ display: 'flex', width: '33%', justifyContent: 'space-between' }}>
                                                    <div>
                                                        <input
                                                            style={{ marginRight: '15px' }}
                                                            type="radio"
                                                            name="role"
                                                            value="Admin"
                                                            id="admin"
                                                            defaultChecked={employeeToEdit && employeeToEdit.role === 'Admin'}
                                                            required
                                                        />
                                                        <label htmlFor="admin">Admin</label>
                                                    </div>
                                                    <div>
                                                        <input
                                                            style={{ marginRight: '15px' }}
                                                            type="radio"
                                                            name="role"
                                                            value="Standard"
                                                            id="standard"
                                                            defaultChecked={employeeToEdit && employeeToEdit.role === 'Standard'}
                                                            required
                                                        />
                                                        <label htmlFor="standard">Standard</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div style={{ display: 'flex', justifyContent: 'right', marginTop: '55px' }}>
                                                <Button
                                                    style={{ borderRadius: '0px', borderWidth: '0px', minWidth: '23%', backgroundColor: '#a4a3a4' }}
                                                    onClick={() => this.setState({ addNewUser: false, employeeToEdit: null, employeeToDelete: false })}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    style={{ borderRadius: '0px', marginLeft: '25px', backgroundColor: '#ed8e61', borderWidth: '0px', minWidth: '23%' }}
                                                >
                                                    {employeeToEdit && employeeToDelete ? 'Delete User' : employeeToEdit ? 'Update User' : 'Add User'}
                                                </Button>
                                            </div>
                                        </form>
                                    </div>
                                ) : (
                                    <div className="user-table">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <span>User Name</span>
                                                    </th>
                                                    <th>
                                                        <span>Role</span>
                                                    </th>
                                                    <th>
                                                        <span>Email</span>
                                                    </th>
                                                    <th>
                                                        <span>Actions</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {employees.length === 0 ? (
                                                    <tr>
                                                        <td colSpan="4" style={{ textAlign: 'center', padding: '20px' }}>
                                                            <p>No users have been added.</p>
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    employees.map(employee => (
                                                        <tr key={employee.id}>
                                                            <td>{employee.name}</td>
                                                            <td>{employee.role}</td>
                                                            <td>{employee.email}</td>
                                                            <td>
                                                                <button style={{ border: 'none', backgroundColor: 'unset' }} onClick={() => this.handleEditEmployee(employee)}><span style={{ borderBottom: '1px solid black' }}>Edit</span></button>
                                                                <button style={{ border: 'none', backgroundColor: 'unset' }} onClick={() => this.handleDeleteEmployee(employee, employee.id)}><span style={{ color: 'red', borderBottom: '1px solid black' }}>Delete</span></button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                )}
                                            </tbody>
                                        </table>

                                        <div style={{ display: 'flex', justifyContent: 'right', marginTop: '50px' }}>
                                            <Button
                                                onClick={() => this.setState({ addNewUser: true })}
                                                style={{ borderRadius: '0px', backgroundColor: '#ed8e61', borderWidth: '0px' }}
                                            >
                                                Add New User
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </Collapse>
                        </div>
                    )}

                    {!selectedUser && selectedProject.id && !transactionsShow && <div className='project-show'>
                        <h4 className='customer-header-style' style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0px' }}>
                            <div>
                                {quotesShow ? "Quote Requests" : selectedProject.slug ? selectedProject.slug : selectedProject.project_name}
                            </div>
                            {quotesShow && (
                                <div className='header-search-part flex-container' style={{ display: 'flex', paddingRight: '70px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }} className='header-search'>
                                        <div className="search-container" style={{ fontSize: '16px' }}>
                                            <input
                                                id="header-table-searchInput"
                                                type="text"
                                                value={this.state.searchQuery}
                                                onChange={this.handleSearchInputChange}
                                                placeholder="Type to search..."
                                                disabled={isDisabled}
                                                className={isDisabled ? 'hidden-input' : 'visible-input'}
                                            />
                                        </div>
                                        <button className='quote-search-btn' onClick={this.handleSearchIconClick}>
                                            <img src='search.svg' alt='Logo' style={{ width: '20px' }} />
                                        </button>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }} className='header-allshow-checkbox'>
                                        <input
                                            type="checkbox"
                                            checked={showOnlyMatching}
                                            onChange={this.toggleShowOnlyMatching}
                                            style={{ width: '16px', height: '16px' }}
                                        />
                                        <span style={{ fontSize: '18px', marginLeft: '7px' }}>Show All</span>
                                    </div>
                                </div>
                            )}


                            <button className={'btn-collapse' + (collapseOpen ? " opened" : "")} onClick={() => this.toggle_collapse(!collapseOpen)}>
                                <FaChevronDown />
                            </button>
                        </h4>

                        <Collapse in={collapseOpen}>
                            <div className={`content ${quotesShow ? "quote-show" : ""}`}>

                                <div className='picture-part'>
                                    <p style={{ marginTop: 20 }}>
                                        <a href={`${process.env.REACT_APP_SERVER_URL}/client/?project=${selectedProject.id}`} target="blank" className="bg-gray link-block link-project-detail bordered">
                                            <img src={process.env.REACT_APP_SERVER_URL + selectedProject.proj_img} className="img-responsive center-block project-item-img" alt="default" />
                                        </a>
                                    </p>
                                </div>

                                <div className='description-part'>
                                    <p>
                                        <b className="embeded_title">How to use your 3D Configurator.</b>
                                    </p>
                                    <p>
                                        <b style={{ fontWeight: 900, fontSize: 15 }}>To embed the 3D Experience directly into your website follow these instructions:</b>
                                    </p>
                                    <p>
                                        1) Copy from one of the code options below. <br />
                                        2) Navigate to your site and edit the HTML code. <br />
                                        3) Paste the code where you want the 3D View to appear on your site.<br />
                                    </p>
                                    <br />
                                    <p>
                                        <b style={{ fontWeight: 900, fontSize: 15 }}>For specific instructions on how to embed the 3D Configurator on various platforms, please be sure to reference your platforms documentation on how to embed content. </b>
                                    </p>
                                    <br />
                                    <p>
                                        <b>iFrame Code</b> <br />
                                        <code>
                                            &lt;iframe
                                            src={`"${process.env.REACT_APP_SERVER_URL}/client/?project=${selectedProject.id}"`}
                                            &nbsp; frameborder="0" width="100%" height="400px"&gt;&lt;/iframe&gt;
                                        </code>
                                    </p>
                                    <p>
                                        <b>Embed Widget</b> <br />
                                        <code>
                                            &lt;iframe
                                            src={`"${process.env.REACT_APP_SERVER_URL}/project-list/${authUser.id}"`}
                                            &nbsp; frameborder="0" width="100%" height="400px"&gt;&lt;/iframe&gt;
                                        </code>
                                    </p>
                                </div>

                                <div className='quote-list'>

                                    <table>
                                        <thead>
                                            <tr>
                                                <th className="table-header" onClick={() => this.handleSort('details')}>
                                                    <span>Details</span></th>
                                                <th className="table-header" onClick={() => this.handleSort('date')}>
                                                    <span>Date</span> {sortColumn === 'date' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
                                                <th className="table-header" onClick={() => this.handleSort('name')}>
                                                    <span>Name</span> {sortColumn === 'name' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
                                                <th className="table-header" onClick={() => this.handleSort('email')}>
                                                    <span>Email</span> {sortColumn === 'email' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
                                                <th className="table-header" onClick={() => this.handleSort('style')}>
                                                    <span>Style</span> {sortColumn === 'style' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
                                                <th className="table-header" onClick={() => this.handleSort('zip')}>
                                                    <span>Zip</span> {sortColumn === 'zip' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
                                                <th className="table-header" onClick={() => this.handleSort('phone')}>
                                                    <span>Phone</span> {sortColumn === 'phone' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
                                                <th className="table-header" onClick={() => this.handleSort('assign')}>
                                                    <span >Assigned</span> {sortColumn === 'assign' && (sortDirection === 'asc' ? '↑' : '↓')}
                                                </th>
                                                <th className="table-header" onClick={() => this.handleSort('status')}>
                                                    <span>Select Status</span> {sortColumn === 'status' && (sortDirection === 'asc' ? '↑' : '↓')}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sortedQuotes.length > 0 ? (
                                                sortedQuotes
                                                    .filter(quote => showOnlyMatching || (selectedProject && selectedProject.project_name === quote.shed_name))
                                                    .map(quote => {
                                                        // Find the employee who has this quote assigned
                                                        const matchedEmployee = employees.find(employee =>
                                                            employee.assigned_quotes.includes(quote.id)
                                                        );

                                                        return (
                                                            <tr className="table-row" key={quote.id}>
                                                                <td>
                                                                    <button className="details-button" onClick={() => this.selectQuote(quote)}>
                                                                        <img src="menuIcon.png" alt="menu" />
                                                                    </button>
                                                                </td>
                                                                <td>{this.getDateTime(quote.date_time)}</td>
                                                                <td>{`${quote.first_name} ${quote.last_name}`}</td>
                                                                <td>{quote.user_email}</td>
                                                                <td>{quote.shed_name}</td>
                                                                <td>{quote.user_zipcode}</td>
                                                                <td>{this.getPhoneNumber(quote.user_phone)}</td>
                                                                <td>
                                                                    <select
                                                                        className={`employee-select ${signedUser.role === "Admin" || signedUser.role === "Standard" ? "select-readonly" : ""}`}
                                                                        onChange={(e) => this.handleEmployeeSelect(e, quote)}
                                                                        value={quote.assigned_user}
                                                                        disabled={
                                                                            signedUser.role ? (
                                                                                (!matchedEmployee) || // Disable if no employee is selected and role exists
                                                                                (signedUser.role === "Standard" && signedUser.name !== (matchedEmployee ? matchedEmployee.name : ""))
                                                                            ) : false // If role does not exist, keep it enabled
                                                                        }
                                                                    >
                                                                        <option defaultChecked className="disabled-white-text"></option>
                                                                        {employees.map((employee) => (
                                                                            <option
                                                                                key={employee.id}
                                                                                value={employee.id}
                                                                                disabled={signedUser.role === "Standard" && signedUser.name !== employee.name}
                                                                            >
                                                                                {employee.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <select
                                                                        className="status-select"
                                                                        value={quote.status}
                                                                        onChange={(e) => this.handleStatusChange(e, quote)}
                                                                        style={{ backgroundColor: statusOptions[quote.status]?.color }}
                                                                    >
                                                                        {Object.entries(statusOptions).map(([key, item]) => (
                                                                            <option key={key} value={key} style={{ backgroundColor: item.color }}>
                                                                                {item.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                            ) : (
                                                <tr>
                                                    <td colSpan="8" className="no-quotes-message">No quotes available</td>
                                                </tr>
                                            )}
                                            {/* {
                                                    sortedQuotes.length > 0 ? (
                                                        sortedQuotes
                                                            .filter(quote => showOnlyMatching || (selectedProject && selectedProject.project_name === quote.shed_name))
                                                            .map(quote => (
                                                                <tr className="table-row" key={quote.id}>
                                                                    <td><button className="details-button" onClick={() => this.selectQuote(quote)}>View Details</button></td>
                                                                    <td>{this.getDateTime(quote.date_time)}</td>
                                                                    <td>{`${quote.first_name} ${quote.last_name}`}</td>
                                                                    <td>{quote.user_email}</td>
                                                                    <td>{quote.user_zipcode}</td>
                                                                    <td>{this.getPhoneNumber(quote.user_phone)}</td>
                                                                </tr>
                                                            ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="6"className="no-quotes-message">No quotes available</td>
                                                        </tr>
                                                    )
                                                } */}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Collapse>

                        {
                            !quotesShow &&
                            <>
                                {
                                    showAnalyticsSection &&
                                    <>
                                        <h4>
                                            Project Analytics
                                            <button className={'btn-collapse' + (pViewCollapseOpen ? " opened" : "")} onClick={() => this.toggle_pview_collapse(!pViewCollapseOpen)}>
                                                <FaChevronDown />
                                            </button>
                                        </h4>

                                        <Collapse in={pViewCollapseOpen}>
                                            <div className='select-period'>
                                                <div className='date-area'>

                                                    {timePeriod == 'Day' && <select onChange={this.select_day.bind(this)} value={selectedDay}>
                                                        {
                                                            days.map((day) => <option key={day} value={day}>{day}</option>)
                                                        }
                                                    </select>}

                                                    {['Month', 'Day'].includes(timePeriod) && <select onChange={this.select_month.bind(this)} value={selectedMonth}>
                                                        {
                                                            months.map((month, i) => <option key={month} value={i}>{month}</option>)
                                                        }
                                                    </select>}

                                                    <select onChange={this.select_year.bind(this)} value={selectedYear}>
                                                        {
                                                            years.map(year => <option key={year} value={year}>{year}</option>)
                                                        }
                                                    </select>

                                                </div>

                                                <div className='button-area'>
                                                    {
                                                        timePeriods.map(item => <button className={timePeriod == item ? "selected" : ""} key={item} onClick={this.select_time_period.bind(this, item)}>{item}</button>)
                                                    }
                                                </div>

                                            </div>


                                            <div className='content customer-views'>
                                                <AnalyticsComp data={CustomerViewData} option={CustomerViewOption} redraw={CustomerViewRedraw} />
                                            </div>

                                            <div className='content pie-chart'>
                                                <ReferalsComp data={RefererViewData} redraw={CustomerViewRedraw} />
                                            </div>

                                        </Collapse>
                                    </>
                                }


                                {
                                    showPricing &&
                                    <>
                                        <h4>
                                            Pricing
                                            <button className={'btn-collapse' + (pricingViewCollapseOpen ? " opened" : "")} onClick={() => this.toggle_pricingview_collapse(!pricingViewCollapseOpen)}>
                                                <FaChevronDown />
                                            </button>
                                        </h4>

                                        <Collapse in={pricingViewCollapseOpen}>
                                            <Pricing
                                                json_data={json_data}
                                                project_data={project_data}
                                                handleSavePricing={this.handleSavePricing}
                                            />
                                        </Collapse>
                                    </>
                                }
                            </>
                        }
                    </div>}
                </div>
            </div>

            <Modal
                show={modalShow}
                onHide={() => this.setModalShow(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modal-quote-details"
            >
                <Modal.Header>
                    <button className='btn-exit' onClick={() => this.setModalShow(false)}>
                        <FaTimes />
                    </button>
                </Modal.Header>

                <Modal.Body>
                    <div className="email-success">
                        <img src='logo192.png' alt='Logo' />
                        <h3>
                            Quote Details
                        </h3>
                        <div className='content-block'>
                            <h5>Lead Details:</h5>
                            <p>Name: {`${selectedQuote.first_name} ${selectedQuote.last_name}`}</p>
                            <p>Email: {selectedQuote.user_email}</p>
                            <p>Phone: {this.getPhoneNumber(selectedQuote.user_phone ? selectedQuote.user_phone : "")}</p>
                            <p>ZIP Code: {selectedQuote.user_zipcode}</p>
                            <p>Date Of Referral: {selectedQuote.date_time}</p>
                            <p>Referral Origin: <br />
                                <a href={`${process.env.REACT_APP_SERVER_URL}/project/${authUser.firstname}/${selectedQuote?.shed_name}?q=${selectedQuote.id}`} rel="noreferrer" target="_blank">{`${process.env.REACT_APP_SERVER_URL}/project/${authUser.firstname}/${selectedQuote?.shed_name}?q=${selectedQuote.id}`}</a>
                            </p>
                        </div>

                        <div className='content-block'>
                            <h5>Product Details:</h5>
                            <p>Shed Name: {selectedQuote.shed_name}</p>
                            <p>Shed Size: {selectedQuote.size_text}</p>
                            <p>Size Price: {formatter.format(selectedQuote.size_cost)}</p>

                            <p>Siding Type: {selectedQuote.siding_name}</p>
                            <p>Siding Price: {formatter.format(selectedQuote.siding_cost)}</p>

                            <p>Roofing Type: {selectedQuote.roofing_name}</p>
                            <p>Roofing Price: {formatter.format(selectedQuote.roofing_cost)}</p>

                            <p>Siding Color: {selectedQuote.siding_color}</p>
                            <p>Siding Color Price: $0.00</p>

                            <p>Trim Color: {selectedQuote.trim_color}</p>
                            <p>Trim Color Price: $0.00</p>

                            <p>Roof Color: {selectedQuote.roof_color}</p>
                            <p>Roof Color Price: $0.00</p>
                        </div>

                        <div className='content-block'>
                            <h5>Accessories:</h5>
                            <p>Front: {selectedQuote.accessories_front}</p>
                            <p>Front Price: {formatter.format(selectedQuote.asset_front_cost)}</p>

                            <p>Back: {selectedQuote.accessories_back}</p>
                            <p>Back Price: {formatter.format(selectedQuote.asset_back_cost)}</p>

                            <p>Left: {selectedQuote.accessories_left}</p>
                            <p>Left Price: {formatter.format(selectedQuote.asset_left_cost)}</p>

                            <p>Right: {selectedQuote.accessories_right}</p>
                            <p>Right Price: {formatter.format(selectedQuote.asset_right_cost)}</p>
                        </div>

                        <div className='content-block'>
                            <h5>Total Quote Price: {formatter.format(selectedQuote.size_cost + selectedQuote.siding_cost + selectedQuote.roofing_cost + selectedQuote.asset_front_cost + selectedQuote.asset_back_cost + selectedQuote.asset_left_cost + selectedQuote.asset_right_cost)}</h5>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer onClick={() => this.setModalShow(false)}>
                    <Button >Ok</Button>
                </Modal.Footer>

            </Modal>

            <Modal
                show={savePricingModalShow}
                onHide={() => this.setSavePricingModalShow(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modal-save-pricing"
            >
                <Modal.Body>
                    <div className="price-success">
                        <h3>
                            Your pricing Information has been saved successfully
                        </h3>
                    </div>
                </Modal.Body>
                <Modal.Footer onClick={() => this.setSavePricingModalShow(false)}>
                    <Button >Ok</Button>
                </Modal.Footer>

            </Modal>

            <Modal
                show={transDetailmodalShow}
                onHide={() => this.setTransDetailModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modal-transaction-details"
            >
                <Modal.Header>

                    <button className='btn-exit' onClick={() => this.setTransDetailModalShow(false)}>
                        <FaTimes />
                    </button>

                </Modal.Header>

                <Modal.Body className='transaction-detail-body'>
                    <div className="email-success">
                        <div style={{ display: 'flex' }}>
                            <img src='logo192.png' alt='Logo' />
                            <h2>
                                Digital Shed Builder
                            </h2>
                        </div>

                        <div className='content-block' style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ width: '50%' }}>
                                <p>724 E Gilmore Rd.</p>
                                <p>Grove City, PA 16127</p>
                                <p>US</p>
                            </div>

                            <p style={{ width: '50%' }}>Phone: (209) 485-0138</p>
                        </div>

                        <div className='content-block'>
                            <h5>Monthly Subscription:</h5>
                            <div className='content-block'>
                                <h6>Shed Configurator</h6>
                                <div className='spacer'></div>
                            </div>
                            <div className='divider'></div>
                            <div className='content-block'>
                                <h6 style={{ marginTop: '8px' }}>Billing Information</h6>
                                <p>{selectedTransaction?.billTo?.firstName} {selectedTransaction?.billTo?.lastName}</p>
                                <div className='spacer'></div>
                            </div>
                            <div className='divider'></div>
                            <div className='content-block' style={{ textAlign: 'right', marginTop: '24px' }}>
                                <p>Shipping: {0.00}</p>
                                <p>Tax: {0.00}</p>
                                <p>Total: {formatter.format(selectedTransaction?.settleAmount)}</p>
                            </div>
                        </div>

                        <div className='content-block'>
                            <h5>Payment Information:</h5>
                            <p>Date/Time: {selectedTransaction?.submitTimeLocal}</p>
                            <p>Transaction ID: {selectedTransaction?.transId}</p>
                            <p>Transaction Status: {selectedTransaction?.transactionStatus === 'settledSuccessfully' ? 'Settled Successfully' : selectedTransaction?.transactionStatus}</p>
                            <p>Authorization Code: {selectedTransaction?.authCode}</p>
                            <p>Payment Method: {selectedTransaction?.payment?.creditCard?.cardType} {selectedTransaction?.payment?.creditCard?.cardNumber}</p>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div style={{ display: 'flex' }}>
                        <Button onClick={() => this.printPDF()}>Print</Button>
                        <Button onClick={() => this.setTransDetailModalShow(false)}>Close</Button>
                    </div>
                </Modal.Footer>

            </Modal>

        </div>

    }
}

const mapStateToProps = state => {
    return {
        authUser: state.auth.user,
        authProjects: state.auth.auth_projects,
        auth_employee_user: state.auth.employee_user,
        auth_allemployee_user: state.auth.allemployee_user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAuthProjectArr: (user_id, callback) => dispatch(getAuthProjects(user_id, callback)),
        auth_check: () => dispatch(authCheckState()),
        update_auth: (user) => dispatch(updateAuthUser(user))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Comp);

