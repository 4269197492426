import React, { Component } from 'react';
import "../css/project-comp.scss";
import Papa from 'papaparse';

class MainComp extends Component {
    constructor(props) {
        super(props);
        const shed_id = props.selected_style || props.json_data?.load_shed;
        this.state = {
            depthSizesByShedId: this.initializeAllDepthSizes(props.json_data, shed_id),
        };
    }

    // Initialize all depth sizes for all sheds from json_data
    initializeAllDepthSizes = (json_data, shed_id) => {
        let depthSizesByShedId = {};
        const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
        for (shed_id in json_data) {
            if (json_data.hasOwnProperty(shed_id) && uuidRegex.test(shed_id)) {
                depthSizesByShedId[shed_id] = this.initializeDepthSizes(json_data, shed_id);
            }
        }
        return depthSizesByShedId;
    };

    // Initialize depth sizes for a specific shed
    initializeDepthSizes = (json_data, shed_id) => {
        // Check if json_data and shed_id are defined
        if (!json_data || !json_data[shed_id]) {
            console.error(`Invalid shed_id: ${shed_id}`);
            return { selectedDepthSizes: {}, unSelectedDepthSizes: {} };
        }
    
        let tempSelectedDepthSizes = {};
        const shedData = json_data[shed_id];
    
        // Check if widthSizes are defined
        if (!shedData.widthSizes) {
            console.error(`widthSizes not defined for shed_id: ${shed_id}`);
            return { selectedDepthSizes: {}, unSelectedDepthSizes: {} };
        }
    
        shedData.widthSizes.forEach((item) => {
            const scale = item?.scale;
    
            if (!item.childHideShowFlag) {
                return;
            }
    
            item.childHideShowFlag.forEach((iitem) => {
                if (iitem?.scale === 'true') {
                    tempSelectedDepthSizes = {
                        ...tempSelectedDepthSizes,
                        [scale]: [...(tempSelectedDepthSizes[scale] || []), iitem?.values[0]],
                    };
                }
            });
        });
    
        let tempUnselectedDepthSizes = {};
        const depthSizes = shedData.depthSizes;
    
        // Check if depthSizes are defined
        if (!depthSizes) {
            console.error(`depthSizes not defined for shed_id: ${shed_id}`);
            return { selectedDepthSizes: {}, unSelectedDepthSizes: {} };
        }
    
        Object.entries(tempSelectedDepthSizes)?.forEach((iitem) => {
            const filterObj = iitem[1];
            let tempUpdatedSizes = depthSizes?.filter((item) => !filterObj?.includes(item?.values[0]));
            if (tempUpdatedSizes?.length === depthSizes?.length) {
                delete tempUnselectedDepthSizes[iitem[0]];
            } else {
                tempUnselectedDepthSizes = {
                    ...tempUnselectedDepthSizes,
                    [iitem[0]]: tempUpdatedSizes,
                };
            }
        });
    
        return { selectedDepthSizes: tempSelectedDepthSizes, unSelectedDepthSizes: tempUnselectedDepthSizes };
    };


    handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;
    
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
                const { data } = results;
                const shed_id = this.props.selected_style || this.props.json_data?.load_shed;
    
                // Initialize newSizeCosts for the current shed_id as an empty object
                let newSizeCosts = { ...this.props.sizeCosts, [shed_id]: {} };
    
                data.forEach((row) => {
                    const { SIZE, PRICE } = row;
                    if (SIZE && PRICE) {
                        // Format the SIZE to match "14 x 14" instead of "14x14"
                        const formattedSize = SIZE.replace(/(\d+)x(\d+)/, '$1 x $2');
                        const formattedPrice = PRICE.replace(/[^\d.]/g, ''); // Remove non-numeric characters
                        
                        newSizeCosts[shed_id] = {
                            ...newSizeCosts[shed_id],
                            [formattedSize]: formattedPrice,
                        };
                    }
                });
    
                this.props.handleChangeSizeCosts(newSizeCosts);
            },
            error: (error) => {
                console.error("Error parsing CSV file: ", error);
            },
        });
    };
    
    
    
    

    handleCheckboxToggle = (e, shed_id, widthSizeValue, depthSizeValue, depthSizes) => {
        const isChecked = e.target.checked;
        this.setState((prevState) => {
            const { depthSizesByShedId } = prevState;
            const { selectedDepthSizes = {}, unSelectedDepthSizes = {} } = depthSizesByShedId[shed_id] || {};

            const updatedSizes = {
                ...selectedDepthSizes,
                [widthSizeValue]: isChecked
                    ? [...(selectedDepthSizes[widthSizeValue] || []), depthSizeValue]
                    : (selectedDepthSizes[widthSizeValue] || []).filter((size) => size !== depthSizeValue),
            };

            let updatedUnSelectedDepthSizes = unSelectedDepthSizes;
            Object.entries(updatedSizes)?.forEach((iitem) => {
                const filterObj = iitem[1];
                let tempUpdatedSizes = depthSizes?.filter((item) => !filterObj?.includes(item?.values[0]));
                if (tempUpdatedSizes?.length === depthSizes?.length) {
                    delete updatedUnSelectedDepthSizes[iitem[0]];
                } else {
                    updatedUnSelectedDepthSizes = {
                        ...updatedUnSelectedDepthSizes,
                        [iitem[0]]: tempUpdatedSizes,
                    };
                }
            });

            const orderMap = new Map(depthSizes.map((item, index) => [item.scale, index]));
            for (const key in updatedUnSelectedDepthSizes) {
                updatedUnSelectedDepthSizes[key].forEach((item) => {
                    const index = orderMap.get(item.scale);
                    item.keyValue = index.toString();
                });
            }

            this.props.handleChangeHideShowSize(updatedUnSelectedDepthSizes, depthSizes);

            return {
                depthSizesByShedId: {
                    ...depthSizesByShedId,
                    [shed_id]: { selectedDepthSizes: updatedSizes, unSelectedDepthSizes: updatedUnSelectedDepthSizes },
                },
            };
        });
    };

    handleInput = (e, shed_id, widthSize, depthSize) => {
        const { sizeCosts } = this.props;
        const key = `${widthSize.scale} x ${depthSize.scale}`;
        const value = e.target.value?.replace('$ ', '').replace(/^0+/, '');
        sizeCosts[shed_id] = { ...sizeCosts[shed_id], [key]: value };
        this.props.handleChangeSizeCosts(sizeCosts);
    };

    validateRiskInput = (e) => {
        const input = e.target;
        let value = input.value.replace(/[^-0-9.]/g, '');
        input.value = value;
    };

    render() {
        const { json_data, selected_style, sizeCosts } = this.props;
        const shed_id = selected_style || json_data?.load_shed;
        if (!shed_id) return <div></div>;

        const loadObj = json_data[shed_id];
        if (!loadObj) return <div></div>;

        const widthSizes = loadObj.widthSizes;
        const depthSizes = loadObj.depthSizes;
        if (!widthSizes || !depthSizes) return <div></div>;

        const { depthSizesByShedId } = this.state;
        const { selectedDepthSizes = {}, unSelectedDepthSizes = {} } = depthSizesByShedId[shed_id] || {};

        return (
            <div className="access-item">
                <div style={{ marginBottom: '2px' }}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <span className='pricing-title'>Shed Sizes</span>
                        <div>
                            <input
                                type="file"
                                accept=".csv"
                                style={{ display: 'none' }}
                                id="upload-csv-input"
                                onChange={this.handleFileUpload}
                            />
                            <button
                                className='upload-pricing-button'
                                onClick={() => document.getElementById('upload-csv-input').click()}
                            >
                                Upload CSV
                            </button>
                        </div>
                    </div>  
                    <div className='pricing-title-bottom'></div>
                </div>
                <div className="size-item">
                    {widthSizes.map((widthSize, i) => (
                        <div style={{ display: 'flex' }} key={i}>
                            <div className='pricing-subtitle-container'>
                                <span className='pricing-subtitle'>{widthSize.scale} Feet: </span>
                            </div>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {depthSizes.map((depthSize, index) => (
                                    <div className='pricing-item-container' key={index}>
                                        <span className='pricing-size-item-title'>
                                            {widthSize.scale} x {depthSize.scale}
                                        </span>
                                        <input
                                            type="text"
                                            className='pricing-item-price'
                                            placeholder=''
                                            value={`$ ${
                                                sizeCosts[shed_id]
                                                    ? sizeCosts[shed_id][`${widthSize.scale} x ${depthSize.scale}`] || '0'
                                                    : '0'
                                            }`}
                                            onChange={(e) => this.handleInput(e, shed_id, widthSize, depthSize)}
                                            onInput={this.validateRiskInput}
                                        />
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-around',
                                                width: '88px',
                                            }}
                                        >
                                            <span style={{ color: '#26455d' }}>Hide Size</span>
                                            <input
                                                type="checkbox"
                                                id={`checkbox_${widthSize.scale}_${depthSize.scale}`}
                                                name={`checkbox_${widthSize.scale}_${depthSize.scale}`}
                                                onChange={(e) =>
                                                    this.handleCheckboxToggle(e, shed_id, widthSize?.scale, depthSize?.values[0], depthSizes)
                                                }
                                                checked={
                                                    selectedDepthSizes[widthSize?.scale]?.includes(depthSize?.values[0]) || false
                                                }
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default MainComp;

